/* react-calendar custom styling */
@import '../../../node_modules/react-calendar/dist/Calendar.css';

.react-calendar__navigation {
  margin-bottom: 0;
  font-size: 16px;
}

.react-calendar__navigation__label {
  text-transform: capitalize;
}

.react-calendar__navigation__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar {
  font-family: 'FoundersGroteskCondensed-Light', serif;
  font-size: 18px;
  color: #1c1c41;
  border: 0px solid #1c1c41;
  width: 100%;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 16px;
  font-weight: 400;
  padding: 0.5em;
  text-decoration: none;
  text-transform: capitalize;
}

abbr {
  text-decoration: none;
}

.react-calendar__month-view__days__day--weekend {
  color: #1c1c41;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #1c1c41;
  opacity: 0.3;
}

.react-calendar__tile {
  border-radius: 9999px;
  position: relative;
  border: 1.5px solid #1c1c41;
  height: 41px;
}

.react-calendar__tile--active {
  background: #badfa4;
  color: #1c1c41;
}

/* Past dates, not selectable */
.react-calendar__tile:disabled {
  background-color: white;
  color: lightgray;
  height: 41px;
}

/* Disabled navigation button, not selectable, e.g. previous month from current date */
.react-calendar__navigation button:disabled {
  background-color: transparent;
  color: transparent;
}

.react-calendar__tile--now {
  background-color: white;
  border: 1.5px solid #1c1c41;
  color: violet;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #1c1c41;
}

.react-calendar__tile--now:hover {
  background-color: #1c1c41;
  color: #1c1c41;
}

.react-calendar__tile--now:enabled {
  color: #1c1c41;
  /* background-color: #badfa4; */
  /* border: 1.5px solid #1c1c41; */
  height: 41px;
  padding-top: 9px;
}

.react-calendar__tile--now:focus {
  color: #1c1c41;
  background-color: #badfa4;
}

.react-calendar__tile--now:enabled:hover {
  background-color: #badfa4;
  color: black;
  /* */
}

.react-calendar__tile--now:enabled {
  /* background-color: #1c1c41; */
  color: #1c1c41;
  border: 1.5px solid black;
}

.react-calendar__tile--now:enabled:focus {
  background-color: #1c1c41;
  color: #1c1c41;
  border: 1.5px solid black;
}

.time-picker__tile--reserved::after {
  content: url('/images/green-circle.svg');
  position: absolute;
  top: 20px;
  left: 16px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #badfa4;
  border: 1.5px solid #1c1c41;
  height: 41px;
  padding: 7px;
}
