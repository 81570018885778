@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /*body {*/
  /*  -webkit-font-smoothing: antialiased;*/
  /*  -moz-osx-font-smoothing: grayscale;*/
  /*}*/

  body.sidebar-open {
    /*@apply h-screen;*/
    /*@apply overflow-y-hidden;*/
    /*@apply touch-none;*/
    /*@apply overscroll-none;*/
    /*@apply md:overflow-y-visible;*/
    /*@apply md:touch-auto;*/
    /*@apply md:overscroll-auto;*/
  }

  @font-face {
    font-family: 'EpikaSerif';
    src: url('../assets/fonts/EpikaSerifExtraCondensedPremium-Medium.otf');
  }
  @font-face {
    font-family: 'FoundersGroteskCondensed-Light';
    src: url('../assets/fonts/FoundersGroteskCondensed-Light.otf');
  }
  @font-face {
    font-family: 'FoundersGroteskCondensed-Regular';
    src: url('../assets/fonts/FoundersGroteskCondensed-Regular.otf');
  }

  body {
    @apply text-blue;
    @apply border-blue;
    @apply placeholder-blue;
    @apply font-body;
    @apply font-normal;
    @apply bg-offwhite;
    @apply overflow-y-scroll;
  }

  h1 {
    @apply text-4xl;
    @apply font-normal;
    @apply font-serif;
    @apply md:text-6xl;
  }
  h2 {
    @apply text-3xl;
    @apply font-normal;
    @apply font-serif;
    @apply md:text-5xl;
  }
  h3 {
    @apply text-2xl;
    @apply font-normal;
    @apply font-serif;
    @apply md:text-4xl;
  }
  h4 {
    @apply text-xl;
    @apply font-normal;
    @apply font-body;
    @apply md:text-3xl;
  }
  h5 {
    @apply text-lg;
    @apply font-normal;
    @apply font-body;
    @apply md:text-2xl;
  }
  h6 {
    @apply text-base;
    @apply font-normal;
    @apply font-body;
    @apply md:text-lg;
  }

  p {
    @apply text-base;
    @apply font-normal;
    @apply font-body;
    @apply my-4;
    @apply md:text-lg;
  }
}

@layer components {
  .spaceGridItemImage {
    @apply duration-300;
    @apply ease-in;
    @apply group-hover:scale-110;
  }
}

@layer utilities {
  .filterExpanded {
    @apply border-0 border-solid border-black bg-white pt-1 pb-2 md:border-[1.5px] md:border-t-0 md:pl-2 md:pr-2;
  }

  .filterSelectionLocationItem {
    @apply w-fit cursor-pointer select-none rounded-full border-2 border-transparent px-4 text-2xl md:border-[1.5px];
  }

  .filterSelectionLocationItemSelected {
    @apply w-fit cursor-pointer select-none rounded-3xl border-[2px] px-4 text-2xl md:border-[1.5px];
  }

  .spaceGridItem {
    @apply text-2xl;
    @apply font-serif;
    @apply text-3xl;
  }

  /* Firefox */
  #selectTime {
    scrollbar-width: thin;
    scrollbar-color: #1c1c41 #0000;
  }

  /* Chrome, Edge, and Safari */
  #selectTime::-webkit-scrollbar {
    width: 5px;
  }

  #selectTime::-webkit-scrollbar-track {
    background: #f000;
    border-radius: 5px;
    margin: 2px 0 2px 0;
  }

  #selectTime::-webkit-scrollbar-thumb {
    background-color: #fff0;
    border-radius: 99px;
    border: 3px solid #1c1c41;
    max-height: 10px;
  }
}
